/* eslint-disable max-len */
// 連接firebase realtime database
import { firebaseConnectRef } from "@/plugins/firebase";
import firebase from "firebase/app";
// 判斷是否為 empty 方法
import { isEmpty } from "@/service/anyService";

import moment from "moment";

// 計算 陣列裡 數字總合方法
import { sum as _sum } from "lodash/math";

// firebase 連接設定
const firebaseConnectSet = {
    roomRef: firebaseConnectRef("chat_rooms"),
    chatRef: firebaseConnectRef("chats"),
    userRef: firebaseConnectRef("users"),
};

// 取得 firebase auth 使用者資料
export const firebaseGetAuthUserData = async () => {
    return new Promise((resolve, reject) => {
        const user = firebase.auth().currentUser;
        if (user) {
            resolve(user);
        } else {
            reject(false);
        }
    });
};
/**
 * 取得使用者資料
 * @param { type String(字串) } receiveUserId  聊天對象使用者id
 */
export const firebaseGetUserData = async (receiveUserId) => {
    try {
        const data = await firebaseConnectSet.userRef.child(receiveUserId).once("value");
        return data.val();
    } catch (err) {
        return { err };
    }
};
/**
 * 更新使用者資料
 * @param { type String(字串) } receiveUserId  聊天對象使用者id
 * @param { type Object(物件) } data 更新資料
 */
export const firebaseUserDataUpdate = async (receiveUserId, userData) => {
    try {
        await firebaseConnectSet.userRef.child(receiveUserId).update({ ...userData, updatedAt: -moment().valueOf() });
    } catch (err) {
        return { err };
    }
};
/**
 * 設定使用者專數聊天對象 列表
 * @param { tpye Object(物件) }  userData 使用者資料
 * @param { type String(字串) } loginUserId  登入使用者id
 * @param { type String(字串) } receiveUserId  聊天對象使用者id
 */
export const firebaseChatRooms = async (userData, loginUserId, receiveUserId) => {
    // 創建 user 資料
    try {
        await firebaseConnectSet.roomRef
            .child(loginUserId)
            .child("users")
            .child(receiveUserId)
            .update({ userData, isProvider: userData.role > 0 ? true : false });
        return { success: true };
    } catch (err) {
        return { err };
    }
};

/**
 * 設定使用者專屬對象聊天內容 列表
 * @param { type Object(物件) } data 聊天室內容
 * @param { type String(字串) } loginUserId  登入使用者id
 * @param { type String(字串) } receiveUserId  聊天對象使用者id
 */
export const firebaseChatMessages = async (data, loginUserId, receiveUserId) => {
    // 創建 user 資料
    try {
        await firebaseConnectSet.chatRef
            .child(loginUserId)
            .child(receiveUserId)
            .push({
                ...data,
                createdAt: firebase.database.ServerValue.TIMESTAMP,
            }).key;
        return { success: true };
    } catch (err) {
        console.log(err);
        return { err };
    }
};

/**
 * 新增聊天室 未讀訊息數量 與最後一筆聊天內容
 * @param { type Object(物件) } data 未讀訊息數量 以及最後一個聊天內容 { ex: unReadMessageCount: 20, message: "測試內容" }
 * @param { type String(字串) } loginUserId  登入使用者id
 * @param { type String(字串) } receiveUserId  聊天對象使用者id
 */
export const firebaseUnReadCountAndLastMessage = async (data, loginUserId, receiveUserId) => {
    // 創建 user 資料
    try {
        await firebaseConnectSet.roomRef
            .child(loginUserId)
            .child("users")
            .child(receiveUserId)
            .update({
                ...data,
                updatedAt: firebase.database.ServerValue.TIMESTAMP,
            });
        return { success: true };
    } catch (err) {
        console.log(err);
        return { err };
    }
};

/**
 * 取得與客服聊天的未讀訊息數量總計
 * @param { type String(字串) } loginUserId 登入使用者id
 * @returns
 */
export const firebaseGetServiceChatUnReadMessageCount = async (loginUserId) => {
    try {
        const serviceChatData = await firebaseConnectRef("chat_rooms")
            .child(loginUserId)
            .child("users")
            .child(process.env.VUE_APP_SERVICE_CHAT_ID)
            .once("value");
        if (serviceChatData.val() === null) {
            return 0;
        }
        return serviceChatData.val().unReadMessageCount === undefined ? 0 : serviceChatData.val().unReadMessageCount;
    } catch (err) {
        console.log("計算客服聊天對象未讀訊息數量異常", err);
        return false;
    }
};

/**
 * 取得 users中 未讀訊息總數量
 * @param { type String(字串) } loginUserId 登入使用者id
 */
export const firebaseGetChatRoomUnReadMessageCountTotal = async (loginUserId) => {
    try {
        const userData = await firebaseConnectRef("chat_rooms").child(loginUserId).child("users").once("value");
        if (userData.val() === null) {
            return 0;
        }
        const count = Object.keys(userData.val()).map((objKey) => {
            // 計算未讀訊息數量 (判斷是否有未讀訊息數量 沒數量回應 0)
            // eslint-disable-next-line no-prototype-builtins
            return !userData.val()[objKey].hasOwnProperty("unReadMessageCount") ? 0 : userData.val()[objKey].unReadMessageCount;
        });
        return _sum(count);
    } catch (err) {
        console.log("計算chat_rooms 聊天對象未讀訊息數量異常", err);
        return false;
    }
};
/**
 * 更新users 中指定對象 所有未讀訊息數量
 * @param { type String(字串) } userId 使用者id
 */
export const firebaseUpdateUserUnReadMessageCount = async (userId) => {
    // 取得客服聊天對象 未讀訊息數量
    const serviceChatCount = await firebaseGetServiceChatUnReadMessageCount(userId);
    // 取得客服聊天室未讀訊息數量失敗時 不往下執行
    if (serviceChatCount === false) {
        return;
    }
    // 本身聊天數量
    const userCount = await firebaseGetChatRoomUnReadMessageCountTotal(userId);
    // 取得自己聊天室未讀訊息數量失敗時 不往下執行
    if (userCount === false) {
        return;
    }
    // 未讀訊息數量總計
    let count = 0;
    if (userCount >= serviceChatCount) {
        // 當本身chat_rooms 未讀訊息數量總數 大於 chat_rooms 中與客服的未讀訊息數量總數時觸發
        /*
            將chat_rooms 所有未讀訊息數量扣除 與 客服聊天室的未讀訊息數量 
            記錄在 users 中自己的banana_id 未讀訊息數量總計 用來作為 cityAi 顯示是否有未讀訊息
        */
        count = userCount - serviceChatCount;
    } else if (userCount === serviceChatCount) {
        // 當本身chat_rooms 未讀訊息數量總數 等於 chat_rooms 中與客服的未讀訊息數量總數時觸發
        /*
            將chat_rooms 所有未讀訊息數量
            記錄在 users 中自己的banana_id 未讀訊息數量總計 用來作為 cityAi 顯示是否有未讀訊息
        */
        count = userCount;
    } else {
        count = userCount;
    }
    try {
        await firebaseConnectSet.userRef.child(userId).update({ unReadMessageCount: count, updatedAt: -moment().valueOf() });
        return { unReadMessageCount: count, unReadMessageCountByServiceChat: serviceChatCount };
    } catch (err) {
        console.log("更新 users 的未讀訊息失敗", err);
    }
};

/**
 * 聊天室對象 未讀訊息歸 0
 * @param { type String(字串) } loginUserId  登入使用者id
 * @param { type String(字串) } receiveUserId  聊天對象使用者id
 */
export const firebaseMessageReaded = async (loginUserId, receiveUserId) => {
    // 創建 user 資料
    try {
        await firebaseConnectSet.roomRef.child(loginUserId).child("users").child(receiveUserId).update({
            unReadMessageCount: 0,
            updatedAt: firebase.database.ServerValue.TIMESTAMP,
        });
        return { success: true };
    } catch (err) {
        return { err };
    }
};

/**
 * 將聊天室已讀訊息時間記錄在聊天對象與登入者的聊天室中
 * @param { type String(字串) } receiveUserId 登入使用者id
 * @param { type String(字串) } loginUserId 聊天對象使用者id
 * @returns
 */
export const firebaseSetReadedTime = async (receiveUserId, loginUserId) => {
    try {
        await firebaseConnectSet.roomRef
            .child(receiveUserId)
            .child("users")
            .child(loginUserId)
            .update({ readedAt: firebase.database.ServerValue.TIMESTAMP });
    } catch (err) {
        return { err };
    }
};

/**
 * 設定客服為機器人
 * @param { type String(字串) } loginUserId  登入使用者id
 * @param { type String(字串) } receiveUserId  聊天對象使用者id
 */
export const firebaseSetServiceChatToBot = async (loginUserId, receiveUserId) => {
    try {
        await firebaseConnectSet.roomRef.child(loginUserId).child("users").child(receiveUserId).update({
            isBot: true,
            updatedAt: firebase.database.ServerValue.TIMESTAMP,
        });
        return { success: true };
    } catch (err) {
        return { err };
    }
};

/**
 * 將客服切換為真人客服非機器人
 * @param { type String(字串) } loginUserId  登入使用者id
 * @param { type String(字串) } receiveUserId  聊天對象使用者id
 */
export const firebaseSetServiceChatToReal = async (loginUserId, receiveUserId) => {
    try {
        await firebaseConnectSet.roomRef.child(loginUserId).child("users").child(receiveUserId).update({
            isBot: false,
            updatedAt: firebase.database.ServerValue.TIMESTAMP,
        });
        return { success: true };
    } catch (err) {
        console.log(err);
        return { err };
    }
};

/**
 * 取得登入使用者 firebase 聊天對象資料
 * @param { type String(字串) } userId  更新對象使用者id
 * @returns
 */
export const firebaseChatRoomUserKeys = async (userId) => {
    try {
        const chatRoomUsers = await firebaseConnectRef(`chat_rooms/${userId}/users`).once("value");
        return chatRoomUsers.val();
    } catch (err) {
        console.log(err);
        return { err };
    }
};

/**
 * 更新 firebase 聊天對象中儲存的關於自己本身的資料
 * @param { type Object(物件) } updateDatas 更新服務商資料
 * @example {
 *   "chat_rooms/{receiveUserId}/users/{userId}/userData": { name: "測試名稱", avatar: "path/...." ... }
 * }
 * @returns
 */
export const firebaseChatRoomUsersDataUpdate = async (updateDatas) => {
    try {
        await firebaseConnectRef().update(updateDatas);
    } catch (err) {
        return { err };
    }
};
/**
 * 過濾多於得使用者資料
 * @param {type Object(物件)} data 使用者資料
 * @returns
 */
export const firebaseFilterSaveUserData = (data) => {
    const userData = {
        avatar: data.avatar,
        age: data.age,
        banana_id: data.banana_id,
        cover: data.cover,
        description: data.description,
        height: data.height,
        isMyFavorite: data.isMyFavorite,
        locale: data.locale,
        media: data.media,
        name: data.name,
        photos: data.photos,
        rating_score: data.rating_score,
        role: data.role,
        service_area: data.service_area,
        status: data.status,
        taboo: data.taboo,
        videos: data.videos,
        weight: data.weight,
        id: data.id,
    };
    return userData;
};
// 取得客服聊天對象會員列表
export const firebaseChatRoomByMembers = async () => {
    try {
        const members = await firebaseConnectRef("chat_rooms")
            .child(process.env.VUE_APP_SERVICE_CHAT_ID)
            .child("users")
            .orderByChild("userData/role") // 找尋 userData 底下的 role key 判斷身份大於 0 者為服務商
            .startAt(0) // 搜尋範圍起始值
            .endAt(0) // 搜尋範圍結束值
            .once("value");
        return members;
    } catch (err) {
        return err;
    }
};
// 取得客服聊天對象服務商列表
export const firebaseChatRoomByProviders = async () => {
    try {
        const providers = await firebaseConnectRef("chat_rooms")
            .child(process.env.VUE_APP_SERVICE_CHAT_ID)
            .child("users")
            .orderByChild("userData/role") // 找尋 userData 底下的 role key 判斷身份大於 0 者為服務商
            .startAt(1) // 搜尋範圍起始值
            .endAt(2) // 搜尋範圍結束值
            .once("value");
        return providers;
    } catch (err) {
        return err;
    }
};
/**
 * 取得使用者資料
 * @param { type String(字串) } receiveUseIrId  聊天對象使用者id
 * @param { type Bool(布林) } isNotServiceChat 判斷是否為客服聊天對象
 * @param { type String(字串) } userId 擁有聊天室的使用者 id
 */
export const firebaseGetChatRoomUserData = async (receiveUserId, isNotServiceChat = false, userId) => {
    try {
        let data = null;
        //  判斷為系統的聊天對象時,更新系統聊天對象未讀數量
        if (!isNotServiceChat) {
            data = await firebaseConnectRef(`chat_rooms/${process.env.VUE_APP_SERVICE_CHAT_ID}/users/${receiveUserId}`).once("value");
        } else {
            //  判斷為非系統的聊天對象時,更新對應user聊天對象未讀數量
            data = await firebaseConnectRef(`chat_rooms/${userId}/users/${receiveUserId}`).once("value");
        }
        // 判斷沒有聊天對象時 回傳值
        if (isEmpty(data.val())) {
            return null;
        }
        const user = data.val().userData;
        // 最後一筆聊天室內容訊息 (判斷是否有訊息 沒訊息 回應空字串)
        const message = isEmpty(data.val().message) ? "" : data.val().message;
        // 計算未讀訊息數量 (判斷是否有未讀訊息數量 沒數量回應 0)
        const count = data.val().unReadMessageCount;
        // 計算未讀訊息數量
        user["unReadMessageCount"] = count;
        // 最後一筆聊天室內容訊息
        user.message = message;
        // 最後更新資料時間
        user.updatedAt = data.val().updatedAt;
        // 已讀訊息時間
        user.readedAt = data.val().readedAt ?? 0;
        // 最後一筆訊息時間
        user.lastMsgAt = isEmpty(data.val().lastMsgAt) ? null : Math.abs(data.val().lastMsgAt);
        // 是否為機器人回應
        user.isBot = data.val().isBot === undefined ? false : data.val().isBot;
        return user;
    } catch (err) {
        return err;
    }
};

/**
 * 刪除指定 user 聊天室
 * @param { type String or Number(字串或數字) } id 刪除user id
 */
export const firebaseDeleteChatUsers = async (id) => {
    if (process.env.VUE_APP_DEBUG === "true") {
        try {
            await firebase.database().ref(`chat_rooms/${id}/`).remove();
        } catch (err) {
            console.log(err);
        }
    }
};
/**
 * 刪除所有 聊天室
 */
export const firebaseDeleteChatRooms = async () => {
    if (process.env.VUE_APP_DEBUG === "true") {
        try {
            await firebase.database().ref(`chat_rooms`).remove();
        } catch (err) {
            console.log(err);
        }
    }
};

/**
 * 更新客服未讀訊息累計
 * @param { type Number(數字) } unReadMessageCount 所有未讀訊息數量總計
 * @param { type Number(數字) } unReadMessageCountByProvider 服務商未讀訊息數量總計
 * @param { type Number(數字) } unReadMessageCountByMember 會員未讀訊息數量總計
 * @returns
 */
export const firebsaeUpdateServiceChatUnReadMessageCount = async (unReadMessageCount, unReadMessageCountByProvider, unReadMessageCountByMember) => {
    try {
        await firebaseConnectRef(`chat_rooms/${process.env.VUE_APP_SERVICE_CHAT_ID}`).update({
            unReadMessageCount,
            unReadMessageCountByProvider,
            unReadMessageCountByMember,
        });
        console.log("work", unReadMessageCount, unReadMessageCountByProvider, unReadMessageCountByMember);
    } catch (err) {
        console.log("更新客服聊天室未讀訊息失敗", err);
        return err;
    }
};

// 取得客服聊天室未讀訊息總計
export const firebaseGetServcieChatUnReadMessageCount = async () => {
    try {
        const data = await firebaseConnectRef(`chat_rooms/${process.env.VUE_APP_SERVICE_CHAT_ID}`).get();
        const defaultData = {
            unReadMessageCount: 0,
            unReadMessageCountByProvider: 0,
            unReadMessageCountByMember: 0,
        };
        if (!data.exists()) {
            return defaultData;
        }
        if (!Object.prototype.hasOwnProperty.call(data.val(), "unReadMessageCount")) {
            return defaultData;
        }
        return {
            unReadMessageCount: data.val().unReadMessageCount,
            unReadMessageCountByProvider: data.val().unReadMessageCountByProvider,
            unReadMessageCountByMember: data.val().unReadMessageCountByMember,
        };
    } catch (err) {
        console.log("取得客服聊天室未讀訊息數量失敗", err);
        return err;
    }
};

export const firebaseServiceReceiviUsersUnReadMessageCount = async () => {
    const snapshot = await firebaseConnectRef("chat_rooms").child(process.env.VUE_APP_SERVICE_CHAT_ID).child("users").get();

    const count = Object.keys(snapshot.val()).map((objKey) => {
        // 計算未讀訊息數量 (判斷是否有未讀訊息數量 沒數量回應 0)
        return !Object.prototype.hasOwnProperty.call(snapshot.val()[objKey], "unReadMessageCount") ? 0 : snapshot.val()[objKey].unReadMessageCount;
    });
    const memberCount = [];
    const providerCount = [];
    snapshot.forEach((item) => {
        // 判斷有 userData key 才往下執行
        if (item.val().userData !== undefined) {
            if (item.val().userData.role == 0 && item.val().unReadMessageCount > 0) {
                memberCount.push(item.val().unReadMessageCount);
            } else if (item.val().userData.role != 0 && item.val().unReadMessageCount > 0) {
                providerCount.push(item.val().unReadMessageCount);
            }
        }
    });
    await firebsaeUpdateServiceChatUnReadMessageCount(_sum(count), _sum(providerCount), _sum(memberCount));
    return { unReadMessageCount: _sum(count), unReadMessageCountByProvider: _sum(providerCount), unReadMessageCountByMember: _sum(memberCount) };
};
